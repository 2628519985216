import React, {useEffect, useMemo, useState} from 'react';
import {useFormContext} from 'react-hook-form';

import {Fade, Grid, Stack, Typography, useTheme} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {useLazyGetPotentialGoalsQuery} from '../../../../features/actionsApi/campaignsSlice';
import {StyledBoxScrollY} from '../../@extended/StyledPaperScrollY';
import {ChooseButtonBase} from './ChooseCommunity';
import {fontAwesomeIcons} from '../../../utils/icons';
import LoadingBox from '../../@extended/LoadingBox';

const ChooseGoal = () => {
  const theme = useTheme();
  const {watch, setValue} = useFormContext();
  const campaignType = watch('campaign_type');

  const [fetchGoals, {data: goals = {}, isSuccess, isLoading, isFetching}] = useLazyGetPotentialGoalsQuery();
  const currentGoals = watch('goals');

  useEffect(() => {
    if (campaignType) {
      fetchGoals(campaignType.toLowerCase());
    }
  }, [campaignType]);

  const handleChoose = (goal) => {
    const goalsSet = new Set(currentGoals);
    if (goalsSet.has(goal.key)) {
      goalsSet.delete(goal.key);
    } else {
      goalsSet.add(goal.key);
    }
    setValue('goals', Array.from(goalsSet));
  };

  const mainComponent = useMemo(() => {
    if (isLoading || isFetching) {
      return <LoadingBox />;
    } else if (isSuccess) {
      return (
        <Fade timeout={1000} in={true}>
          <Stack direction="column" rowGap={2}>
            <Stack direction="column">
              <Typography variant="h4" fontWeight={'fontWeightRegular'} textAlign="center">
                {goals?.header}
              </Typography>
              <Typography variant="h5" fontWeight={'fontWeightRegular'} color={'text.subtitle'} textAlign="center">
                {goals?.subheader}
              </Typography>
            </Stack>
            <Grid container spacing={2} sx={{justifyContent: 'center'}}>
              {goals?.subtypes?.map((goal, i) => {
                let border = `1px solid ${theme.palette.grey[300]}`;

                if (currentGoals.includes(goal.key)) {
                  border = `1px solid ${theme.palette.secondary.main}`;
                }

                return (
                  <Grid item key={`launch-type-icon-${i}`}>
                    <ChooseButtonBase
                      sx={{
                        border: border,
                        width: '160px',
                        height: '175px'
                      }}
                      onClick={() => handleChoose(goal)}
                    >
                      <Stack direction="column" rowGap={1} alignItems="center" p={2}>
                        <FontAwesomeIcon icon={fontAwesomeIcons[goal.icon]} size="3x" style={{color: theme.palette.grey[600]}} />
                        <Typography variant="h6" fontWeight={'fontWeightRegular'} textAlign="center">
                          {goal.name}
                        </Typography>
                      </Stack>
                    </ChooseButtonBase>
                  </Grid>
                );
              })}
            </Grid>
          </Stack>
        </Fade>
      );
    }
  }, [isFetching, isLoading, isSuccess, currentGoals]);

  return (
    <StyledBoxScrollY id="choose-goal" width="100%" height="100%" display="flex" justifyContent="center" p={2} sx={{overflowY: 'scroll'}}>
      {mainComponent}
    </StyledBoxScrollY>
  );
};

export default ChooseGoal;
