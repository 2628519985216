import React, {Fragment} from 'react';

// mui imports
import {Button} from '@mui/material';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {fontAwesomeIcons} from '../../../utils/icons';
import {useDispatch} from 'react-redux';
import {activeModal} from '../../../../app/store/reducers/menu';
import NewCampaign from './NewCampaign';

const StartCampaign = () => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(activeModal({type: 'startCampaign', sfid: null}));
  };

  return (
    <Fragment>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleClick}
        endIcon={<FontAwesomeIcon icon={fontAwesomeIcons.faCirclePlus} />}
      >
        Start a campaign
      </Button>
      <NewCampaign />
    </Fragment>
  );
};

export default StartCampaign;
