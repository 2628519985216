import React from 'react';

import {Box, Typography} from '@mui/material';

const ErrorBox = () => {
  return (
    <Box width="100%" height={200} display="flex" justifyContent="center" alignItems="center">
      <Typography variant="subtitle1" fontWeight={'fontWeightRegular'} color={'text.subtitle'}>
        Something went wrong.
      </Typography>
    </Box>
  );
};

export default ErrorBox;
