import React from 'react';
import moment from 'moment';
import {useFormContext} from 'react-hook-form';
import PropTypes from 'prop-types';

import {Box, ButtonBase, Chip, Divider, Paper, Stack, Typography, useTheme} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {fontAwesomeIcons} from '../../../utils/icons';
import {moneyFormatter} from '../../@extended/FormatDisplay';

const targetData = [
  {
    id: 'unit_types',
    label: 'Unit Types',
    format: 'default'
  },
  {
    id: 'rent_amount_min',
    label: 'Price Min',
    format: 'money'
  },
  {
    id: 'rent_amount_max',
    label: 'Price Max',
    format: 'money'
  },
  {
    id: 'start_date_min',
    label: 'Lease Start',
    format: 'date'
  }
];

const RecommendedCampaignCard = ({campaign}) => {
  const theme = useTheme();
  const {setValue, watch} = useFormContext();
  let icon;
  const units = parseInt(campaign?.target?.total_units) || 0;

  const darkGrey = theme.palette.grey[600];
  const lightGrey = theme.palette.grey[300];

  const campaignsActions = [];

  for (const action of campaign.actions) {
    const name = action.name.toLowerCase();

    campaignsActions.push(action.offers.map((ele) => ({...ele, titleName: name})));
    icon = action.icon;
  }

  const offers = campaignsActions.flat().map((offer, i) => {
    const {reward_type, reward_amount, titleName, paid_for_by} = offer;
    let label;
    let chipStyle = {
      backgroundColor: 'rgba(160, 217, 159, 0.5)',
      color: '#11A10E',
      overflowWrap: 'break-word',
      whiteSpace: 'normal',
      textOverflow: 'clip'
    };

    if (paid_for_by !== 'Owner') {
      chipStyle = {
        backgroundColor: 'rgba(20, 156, 224, 0.2)',
        color: '#149CE0'
      };
    }

    if (reward_type === 'Flat dollar amount') {
      label = `$${parseInt(reward_amount)} for ${titleName}`;
    } else {
      label = `${reward_amount}% for ${titleName}`;
    }

    return (
      <Chip
        key={`offer-${i}`}
        size="small"
        label={
          <Box
            sx={{
              overflowWrap: 'break-word',
              whiteSpace: 'normal',
              textOverflow: 'clip',
              display: 'flex',
              alignItems: 'center',
              textAlign: 'start'
            }}
          >
            {label}
          </Box>
        }
        sx={{
          '&.MuiChip-root': {...chipStyle, height: 'auto', padding: '3px 0', fontSize: '10px'}
        }}
      />
    );
  });

  return (
    <ButtonBase
      sx={{
        padding: 0,
        width: '80%'
      }}
      onClick={() => setValue('campaign_id', campaign.id)}
    >
      <Stack direction="column" alignItems="center" sx={{width: '100%', height: '100%'}}>
        <Stack
          direction="column"
          rowGap={1}
          alignItems="center"
          p={2}
          sx={{
            border: `1px solid ${watch('campaign_id') === campaign.id ? theme.palette.secondary.main : lightGrey}`,
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
            borderBottom: `1px solid ${theme.palette.blue[0]}`,
            width: '100%',
            padding: 2,
            height: '100%'
          }}
        >
          <Stack direction="row" columnGap={1} alignItems="center">
            <FontAwesomeIcon icon={fontAwesomeIcons[icon]} size="lg" />
            <Typography variant="h4" fontWeight={'fontWeightRegular'} textAlign="center">
              {campaign.subtype}
            </Typography>
          </Stack>
          <Typography variant="h6" textAlign="center" sx={{color: darkGrey}}>
            {campaign.description}
          </Typography>
        </Stack>
        <Stack direction="column" rowGap={1} alignItems="center" width="100%">
          <Box
            sx={{
              backgroundColor: theme.palette.blue[0],
              border: `1px solid ${watch('campaign_id') === campaign.id ? theme.palette.secondary.main : lightGrey}`,
              borderBottomLeftRadius: '12px',
              borderBottomRightRadius: '12px',
              width: '100%',
              padding: 2,
              height: '100%'
            }}
          >
            <Stack direction="column">
              <Stack direction="row" columnGap={1} sx={{width: '100%'}}>
                <Paper sx={{borderRadius: '12px', width: '50%', boxShadow: 'none'}}>
                  <Stack
                    direction="column"
                    alignItems="flex-start"
                    justifyContent="space-between"
                    divider={<Divider orientation="horizontal" flexItem />}
                  >
                    <Box p="12px">
                      <Typography variant="h8_light" sx={{color: darkGrey, textAlign: 'left'}}>
                        Your residents get
                      </Typography>
                    </Box>
                    <Stack direction="column" rowGap={0.5} alignItems="flex-start" p="12px">
                      {offers}
                    </Stack>
                  </Stack>
                </Paper>
                <Paper sx={{borderRadius: '12px', width: '50%', boxShadow: 'none'}}>
                  <Stack direction="column" alignItems="flex-start" divider={<Divider orientation="horizontal" flexItem />}>
                    <Box p="12px">
                      <Typography variant="h8_light" sx={{color: darkGrey, textAlign: 'left'}}>
                        {units == 1 ? '1 unit' : `${units} units`} affected
                      </Typography>
                    </Box>
                    <Stack direction="column" rowGap={0.5} alignItems="flex-start" p="12px">
                      {targetData.map((ele, i) => {
                        let value = campaign?.target?.[ele.id];

                        if (ele.format === 'money') {
                          value = value ? moneyFormatter.format(value) : '$0';
                        } else if (ele.format === 'date') {
                          value = value ? `After: ${moment(value).format('MM/YY')}` : 'no start date';
                        } else {
                          value = value ?? 'None';
                        }

                        return (
                          <Chip
                            key={`${i}-target-chip`}
                            size="small"
                            sx={{
                              '&.MuiChip-root': {color: darkGrey, fontSize: '10px'}
                            }}
                            label={`${ele.label}: ${value}`}
                          />
                        );
                      })}
                    </Stack>
                  </Stack>
                </Paper>
              </Stack>
              <Box width="100%" display="flex" justifyContent="flex-end" alignItems="center" p={1}>
                <Typography variant="body2" sx={{color: darkGrey}}>
                  You can edit these later
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </ButtonBase>
  );
};

RecommendedCampaignCard.propTypes = {
  campaign: PropTypes.object.isRequired
};

export default RecommendedCampaignCard;
