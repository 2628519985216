import axios from 'axios';
import {createApi} from '@reduxjs/toolkit/query/react';

import CONSTANTS from '../../commons/constants/envConstants';
import {getCurrentToken, initializeToken} from '../../commons/utils/cookies';

initializeToken();

const axiosBaseQuery =
  () =>
  async ({url, method, data, params}) => {
    try {
      const baseUrl = CONSTANTS.MESSAGING_SERVICE_API_URL;
      const configuredHeaders = {
        Authorization: CONSTANTS.MESSAGING_SERVICE_API_KEY
      };

      const result = await axios({
        url: `${baseUrl}${url}`,
        method,
        data,
        params,
        headers: configuredHeaders
      });

      return {data: result.data?.data ?? result.data};
    } catch (axiosError) {
      const err = axiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message
        }
      };
    }
  };

export const messagingApiSlice = createApi({
  reducerPath: 'messagingApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getEmailHistory: builder.query({
      query: (id) => ({
        url: `emails/list/${id}/`,
        method: 'get'
      }),
      providesTags: (_res, err, arg) => (!err ? [{type: 'EmailHistory', id: arg}] : [])
    }),
    getTextHistory: builder.query({
      query: (id) => ({
        url: `text_messages/list/${id}/`,
        method: 'get'
      }),
      providesTags: (_res, err, arg) => (!err ? [{type: 'TextHistory', id: arg}] : [])
    }),
    getNotificationHistory: builder.query({
      query: (id) => ({
        url: `push_notifications/list/${id}/`,
        method: 'get'
      }),
      providesTags: (_res, err, arg) => (!err ? [{type: 'NotificationHistory', id: arg}] : [])
    })
  })
});

export const {useLazyGetEmailHistoryQuery, useLazyGetNotificationHistoryQuery, useLazyGetTextHistoryQuery} = messagingApiSlice;
