import React, {useEffect, useMemo} from 'react';
import {useFormContext} from 'react-hook-form';
import {useSelector} from 'react-redux';

import {CircularProgress, Fade, Stack, Typography, useTheme} from '@mui/material';

import {StyledBoxScrollY} from '../../@extended/StyledPaperScrollY';
import {useLazyGetRecommendedCampaignsQuery} from '../../../../features/actionsApi/campaignsSlice';
import {getDashboardAccount} from '../../../../app/store/reducers/dashboard';

import ErrorBox from '../../@extended/ErrorBox';
import RecommendedCampaignCard from './RecommendedCampaignCard';

const RecommendedCampaign = () => {
  const theme = useTheme();
  const darkGrey = theme.palette.grey[600];
  const {getValues} = useFormContext();
  const dashboardAccount = useSelector(getDashboardAccount);
  const [getCampagns, {data, isSuccess, isLoading, isFetching, isError}] = useLazyGetRecommendedCampaignsQuery();

  useEffect(() => {
    const values = getValues();

    if (dashboardAccount?.sfid) {
      const stringGoals = values.goals.join(',');
      const obj = {
        data: {
          goals_optimized_for: stringGoals,
          rental_community_id: values.community_id,
          account_sfid: dashboardAccount.sfid
        },
        campaignType: values.campaign_type
      };
      getCampagns(obj);
    }
  }, [dashboardAccount]);

  const mainComponent = useMemo(() => {
    if (isLoading || isFetching) {
      return (
        <Stack direction="column" rowGap={2} alignItems="center" justifyContent="center" sx={{height: '40vh'}}>
          <CircularProgress thickness={5} size={40} sx={{color: theme.palette.primary[200]}} />
          <Typography variant="h3_light" sx={{color: darkGrey}}>
            Analyzing data from similar
          </Typography>
          <Typography variant="h3_light" sx={{color: darkGrey}}>
            Stake Network properties...
          </Typography>
        </Stack>
      );
    } else if (isError) {
      return <ErrorBox />;
    } else if (isSuccess) {
      let subComponent;
      if (data.length === 0) {
        subComponent = (
          <Stack direction="column" rowGap={3} alignItems="center" justifyContent={'center'}>
            <Typography variant="h6" textAlign="center" sx={{color: darkGrey}}>
              Got recommendation for 0 campaigns
            </Typography>
          </Stack>
        );
      } else {
        subComponent = (
          <Stack direction="column" rowGap={3} alignItems="center">
            <Stack direction="column" rowGap={1} alignItems="center">
              <Typography variant="h5" fontWeight={'fontWeightMedium'} textAlign="center">
                Your Recommended Campaign
              </Typography>
            </Stack>
            {data.map((campaign, i) => {
              return <RecommendedCampaignCard key={`launch-type-icon-${i}`} campaign={campaign} />;
            })}
          </Stack>
        );
      }
      return (
        <Fade timeout={1000} in={true}>
          {subComponent}
        </Fade>
      );
    }
  }, [isLoading, isSuccess, isError, isFetching]);

  return (
    <StyledBoxScrollY
      id="choose-community"
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      p={2}
      sx={{overflowY: 'scroll'}}
    >
      {mainComponent}
    </StyledBoxScrollY>
  );
};

export default RecommendedCampaign;
