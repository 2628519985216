import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {getAuthorizedStatus} from '../../app/store/reducers/session';
import {useLazyGetSessionInfoQuery, useLazyGetSsoSessionInfoQuery} from '../../features/loyaltyCloudApi/session';
import {getTokenVerificationLoadingStatus} from '../../app/store/reducers/loading';
import LoadingBox from '../../commons/components/@extended/LoadingBox';

const TokenVerification = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {search} = useLocation();
  const newParams = new URLSearchParams(search);
  const token = newParams.get('token');

  const isAuthorized = useSelector(getAuthorizedStatus);
  const tokenStatus = useSelector(getTokenVerificationLoadingStatus);

  const [verifyToken] = useLazyGetSessionInfoQuery();
  const [verifySsoToken] = useLazyGetSsoSessionInfoQuery();

  useEffect(() => {
    const isRegularLogin = location.pathname === '/verify_login';
    const isSsoLogin = location.pathname === '/verify_sso_login';

    if (isRegularLogin && token && !tokenStatus.isLoading && !tokenStatus.isError && !tokenStatus.isSuccess) {
      verifyToken(token);
    } else if (isSsoLogin && token && !tokenStatus.isLoading && !tokenStatus.isError && !tokenStatus.isSuccess) {
      verifySsoToken(token);
    }
  }, [tokenStatus, token, verifyToken, verifySsoToken, location.pathname]);

  useEffect(() => {
    if (isAuthorized || tokenStatus.isSuccess) {
      navigate('/home');
    } else if (tokenStatus.isError) {
      navigate('/login');
    }
  }, [isAuthorized, navigate, tokenStatus.isSuccess, tokenStatus.isError]);

  return <LoadingBox />;
};

export default TokenVerification;
