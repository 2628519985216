import {Divider, styled} from '@mui/material';

export const StyledMarginWidthDividerMd = styled(Divider)({
  margin: '0 -24px'
});
export const StyledMarginWidthDividerSm = styled(Divider)({
  margin: '0 -16px'
});
export const StyledMarginWidthDividerXs = styled(Divider)({
  margin: '0 -8px'
});

export const StyledMarginHeightDividerMd = styled(Divider)({
  margin: '-24px 0'
});

export const StyledMarginHeightDividerSm = styled(Divider)({
  margin: '-16px 0'
});

export const StyledMarginHeightDividerXs = styled(Divider)({
  margin: '-8px 0'
});
