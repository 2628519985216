import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import DashboardComponent from './dashboardComponent';
import {getOpenItem} from '../../../app/store/reducers/menu';
import {getDashboardCommunities, getDashboardCommunity} from '../../../app/store/reducers/dashboard';
import {getCommunityLoadingStatus} from '../../../app/store/reducers/loading';

const CommunityDashboard = ({createHandleMenuClick}) => {
  const type = 'communities';
  const openItem = useSelector(getOpenItem);
  const dashboardCommunity = useSelector(getDashboardCommunity);
  const dashboardCommunities = useSelector(getDashboardCommunities);
  const isActive = openItem === type.toLowerCase();
  const communitiesLoadingStatus = useSelector(getCommunityLoadingStatus);

  const fCommunities = useMemo(() => {
    const communities = [...dashboardCommunities].map((c) => ({sfid: c.sfid, name: c.name}));
    return [{sfid: 'all', name: `All communities (${dashboardCommunities.length || 0})`}, ...communities];
  }, [dashboardCommunities]);

  return (
    <DashboardComponent
      type={type}
      dashboardObj={dashboardCommunity}
      isLoading={communitiesLoadingStatus.isLoading}
      isSuccess={communitiesLoadingStatus.isSuccess}
      data={fCommunities}
      createHandleMenuClick={createHandleMenuClick}
      isActive={isActive}
    />
  );
};

CommunityDashboard.propTypes = {
  createHandleMenuClick: PropTypes.func
};

export default CommunityDashboard;
