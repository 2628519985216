import React, {useEffect, useMemo} from 'react';
import {useFormContext} from 'react-hook-form';
import {useSelector} from 'react-redux';

import {Box, Divider, Fade, Grid, Stack, Typography, useTheme} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {getCommunities} from '../../../../app/store/reducers/entities';
import {useLazyGetSupportedCampaignTypesQuery} from '../../../../features/actionsApi/campaignsSlice';
import {ChooseButtonBase} from './ChooseCommunity';
import {fontAwesomeIcons} from '../../../utils/icons';
import {StyledBoxScrollY} from '../../@extended/StyledPaperScrollY';

import LoadingBox from '../../@extended/LoadingBox';

const ChooseCampaignType = ({setCurrentPage}) => {
  const {watch, setValue} = useFormContext();
  const theme = useTheme();
  const communitySfid = watch('community_sfid');
  const communities = useSelector(getCommunities);
  const community = communities.find((c) => c.sfid === communitySfid);
  const darkGrey = theme.palette.grey[600];

  const [fetchTypes, {data: types = {}, isSuccess, isLoading, isFetching}] = useLazyGetSupportedCampaignTypesQuery();

  useEffect(() => {
    if (community && community?.id) {
      fetchTypes(community.id);
    }
  }, [community]);

  const handleChoose = (type) => {
    setValue('campaign_type', type.key);
    const metrics = {
      baseline_occupancy__c: type?.default_baseline_metric_values?.occupancy,
      baseline_bad_debt_rate__c: type?.default_baseline_metric_values?.badDebtRate,
      previous_concessions__c: type?.default_baseline_metric_values?.concession,
      target_new_lease_trade_out_rate__c: type?.default_baseline_metric_values?.targetIncrease,
      baseline_renewal_trade_out_rate__c: type?.default_baseline_metric_values?.renewalConversion,
      target_renewal_trade_out_rate__c: type?.default_baseline_metric_values?.renewalTradeOut
    };
    setValue('metrics', metrics);
    setCurrentPage(2);
  };

  const mainComponent = useMemo(() => {
    if (isFetching || isLoading) {
      return <LoadingBox />;
    } else if (isSuccess) {
      return (
        <Fade timeout={1000} in={true}>
          <Stack direction="column" rowGap={2}>
            <Stack direction="column">
              <Typography variant="h4" fontWeight={'fontWeightRegular'} textAlign="center">
                {types?.header}
              </Typography>
              <Typography variant="h5" fontWeight={'fontWeightRegular'} color={'text.subtitle'} textAlign="center">
                {types?.subheader}
              </Typography>
            </Stack>
            <Grid container spacing={2} sx={{justifyContent: 'center'}}>
              {types?.subtypes.map((type, i) => (
                <Grid item key={`campaign-type-icon-${i}`} xs={4} sx={{height: '300px'}}>
                  <ChooseButtonBase
                    id={`tour-choose-campaign-type-${type.name.toLowerCase()}`}
                    sx={{
                      width: '100%',
                      height: '100%',
                      padding: 0,
                      boxShadow: 'none'
                    }}
                    onClick={() => handleChoose(type)}
                  >
                    <Stack direction="column" alignItems="center" divider={<Divider />} sx={{width: '100%', height: '100%'}}>
                      <Stack direction="column" rowGap={2} alignItems="center" width="100%" height="30%">
                        <Box
                          sx={{
                            backgroundColor: '#f1f5f9',
                            borderTopLeftRadius: '10px',
                            borderTopRightRadius: '10px',
                            width: '100%',
                            padding: 2,
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <FontAwesomeIcon size="3x" icon={fontAwesomeIcons[type.icon]} style={{color: darkGrey}} />
                        </Box>
                      </Stack>
                      <Stack direction="column" rowGap={2} alignItems="center" p={2} height="70%" width="100%">
                        <Stack direction="column" rowGap={2} alignItems="center">
                          <Typography variant="h6" textAlign="center">
                            {type?.name}
                          </Typography>
                          <Typography variant="body1" textAlign="center" color={'text.subtitle'}>
                            {type?.tooltip}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  </ChooseButtonBase>
                </Grid>
              ))}
            </Grid>
          </Stack>
        </Fade>
      );
    }
  }, [isSuccess, isLoading, isFetching]);

  return (
    <StyledBoxScrollY
      id="choose-campaign-type"
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      p={2}
      sx={{overflowY: 'scroll'}}
    >
      {mainComponent}
    </StyledBoxScrollY>
  );
};

export default ChooseCampaignType;
