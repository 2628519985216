import {createSlice} from '@reduxjs/toolkit';

// initial state
const initialState = {
  account: {sfid: null, name: null},
  community: {sfid: null, name: null},
  campaign: {sfid: null, name: null},
  campaigns: [],
  communities: []
};

// ==============================|| SLICE - DASHBOARD ||============================== //

const dashboard = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboard: (state, {payload}) => {
      const {type, data} = payload;
      const lowerCaseType = type.toLowerCase();

      if (lowerCaseType == 'communities') {
        state.community = data;
      } else if (lowerCaseType == 'campaigns') {
        state.campaign = data;
      } else {
        state.account = data;
      }
    },

    setCampaignsSelected: (state, {payload}) => {
      state.campaigns = payload.filter((p) => p.name).sort((a, b) => a.name.localeCompare(b.name));
    },

    setCommunitiesSelected: (state, {payload}) => {
      state.communities = payload;
    }
  }
});

export const {setDashboard, setCampaignsSelected, setCommunitiesSelected} = dashboard.actions;
export default dashboard.reducer;

export const getDashboardAccount = (state) => state.dashboard.account;
export const getDashboardCommunity = (state) => state.dashboard.community;
export const getDashboardCampaign = (state) => state.dashboard.campaign;
export const getDashboardCampaigns = (state) => state.dashboard.campaigns;
export const getDashboardCommunities = (state) => state.dashboard.communities;
