import React from 'react';
import {Outlet} from 'react-router-dom';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => (
  <>
    <Outlet />
  </>
);

export default MinimalLayout;
