import {coreApiSlice} from './apiSlice';

export const coreMetricsApi = coreApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCommunityESG: builder.query({
      query: (sfid) => ({
        url: `esg_dashboard/property/${sfid}/`,
        method: 'get'
      }),
      providesTags: (_result, _error, category) => [{type: 'ESG', id: category}]
    }),
    getAccountESG: builder.query({
      query: (sfid) => ({
        url: `esg_dashboard/account/${sfid}/`,
        method: 'get'
      }),
      providesTags: (_result, _error, category) => [{type: 'ESG', id: category}]
    }),
    getEngagementByAccountId: builder.query({
      query: (sfid) => ({
        url: `lc_homepage/account/${sfid}/`,
        method: 'get'
      }),
      providesTags: (_result, _error, category) => [{type: 'Engagement', id: category}]
    }),
    getDaysOnMarketByCommunitySfid: builder.query({
      query: (sfid) => ({
        url: `rental_communities/${sfid}/days_on_market_with_comps/`,
        method: 'get'
      }),
      providesTags: [{type: 'Days on market', id: 'LIST'}]
    }),
    getDaysOnMarketByCampaignSfid: builder.query({
      query: (sfid) => ({
        url: `reward_campaigns/${sfid}/days_on_market_with_comps/`,
        method: 'get'
      }),
      providesTags: [{type: 'Days on market', id: 'LIST'}]
    }),
    updateFeatureVisibility: builder.mutation({
      query: (params) => {
        const sfid = params.sfid;
        delete params.sfid;
        return {
          method: 'patch',
          url: `lc_features/change_visibility/${sfid}/`,
          params: {...params}
        };
      }
    }),
    getConcessionSavingByCampaignSfid: builder.query({
      query: (sfid) => ({
        url: `campaign/${sfid}/concessions_savings/`,
        method: 'get'
      }),
      providesTags: [{type: 'Concession savings', id: 'LIST'}]
    }),
    updateConcessionSavingByCampaignSfid: builder.mutation({
      query: ({sfid, params}) => ({
        url: `campaign/${sfid}/concessions_savings/`,
        method: 'get',
        params: {...params}
      })
    }),
    getConcessionSavingByPropertySfid: builder.query({
      query: (sfid) => ({
        url: `property/${sfid}/concessions_savings/`,
        method: 'get'
      }),
      providesTags: [{type: 'Concession savings', id: 'LIST'}]
    }),
    updateConcessionSavingByPropertySfid: builder.mutation({
      query: ({sfid, params}) => ({
        url: `property/${sfid}/concessions_savings/`,
        method: 'get',
        params: {...params}
      })
    }),
    getGlanceData: builder.query({
      query: (sfid) => ({
        url: `accounts/${sfid}/at-a-glance-data/`,
        method: 'get'
      }),
      providesTags: [{type: 'Glance', id: 'LIST'}]
    }),
    editAlertsLike: builder.mutation({
      query: ({id, likeType, isClicked}) => ({
        url: `accounts/alerts/${id}/`,
        method: 'patch',
        data: {like_type: likeType, is_clicked: isClicked}
      }),
      async onQueryStarted({id, likeType, isClicked}, {dispatch, queryFulfilled}) {
        const patchResult = dispatch(
          coreApiSlice.util.updateQueryData('getAccountAlerts', undefined, (draft) => {
            const alertToUpdate = draft.find((alert) => alert.property_sfid === id);
            if (alertToUpdate) {
              if (likeType === 'like') {
                alertToUpdate.thumbs_up = isClicked;
                if (isClicked) {
                  alertToUpdate.thumbs_down = false;
                }
              } else {
                alertToUpdate.thumbs_down = isClicked;
                if (isClicked) {
                  alertToUpdate.thumbs_up = false;
                }
              }
            }
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      }
    }),
    getDelinquencyByPortfolio: builder.query({
      query: ({sfid, community, timeFilter, isStakeAdmin}) => ({
        url: `property_info/delinquency_by_portfolio/${sfid}/analytics/`,
        method: 'get',
        params: {community, time_filter: timeFilter, is_stake_admin: isStakeAdmin}
      }),
      providesTags: [{type: 'Delinquency by portfolio', id: 'LIST'}]
    }),
    getRenewalsCoversionsByAccountSfid: builder.query({
      query: ({sfid, params}) => ({
        url: `renewals_conversions/${sfid}/`,
        method: 'get',
        params: {...params}
      }),
      providesTags: [{type: 'Renewals conversions', id: 'LIST'}]
    }),
    getRenewalsTradeOutsByAccountSfid: builder.query({
      query: ({sfid, params}) => ({
        url: `renewals_trade_outs/${sfid}/`,
        method: 'get',
        params: {...params}
      }),
      providesTags: [{type: 'Renewals trade outs', id: 'LIST'}]
    }),
    getSpendingDataByAccount: builder.query({
      query: (id) => ({
        method: 'get',
        url: 'community_spending/',
        params: {account_id: id}
      }),
      providesTags: (_result, _error, {id}) => [{type: 'Spending chart', id}]
    })
  }),
  overrideExisting: false
});

export const {
  useLazyGetAccountESGQuery,
  useLazyGetCommunityESGQuery,
  useLazyGetEngagementByAccountIdQuery,
  useLazyGetDaysOnMarketByCampaignSfidQuery,
  useLazyGetDaysOnMarketByCommunitySfidQuery,
  useUpdateFeatureVisibilityMutation,
  useLazyGetConcessionSavingByCampaignSfidQuery,
  useUpdateConcessionSavingByCampaignSfidMutation,
  useLazyGetConcessionSavingByPropertySfidQuery,
  useUpdateConcessionSavingByPropertySfidMutation,
  useGetGlanceDataQuery,
  useEditAlertsLikeMutation,
  useGetDelinquencyByPortfolioQuery,
  usePrefetch,
  useGetRenewalsCoversionsByAccountSfidQuery,
  useGetRenewalsTradeOutsByAccountSfidQuery,
  useGetSpendingDataByAccountQuery
} = coreMetricsApi;
