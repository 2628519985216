import React from 'react';
import PropTypes from 'prop-types';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {DialogTitle, IconButton, Stack} from '@mui/material';

import {fontAwesomeIcons} from '../../utils/icons';

export const DialogTitleWithCloseIcon = (props) => {
  const {children, onClose, ...other} = props;

  return (
    <DialogTitle sx={{m: 0, p: 2, pl: 3}} {...other}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction={'column'} justifyContent="center">
          {children}
        </Stack>
        <IconButton onClick={onClose} sx={{width: '40px'}} disableFocusRipple disableRipple disableTouchRipple>
          <FontAwesomeIcon icon={fontAwesomeIcons['faXmark']} />
        </IconButton>
      </Stack>
    </DialogTitle>
  );
};

DialogTitleWithCloseIcon.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func
};
