import React from 'react';
import PropTypes from 'prop-types';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Stack, Typography, useTheme} from '@mui/material';

import {fontAwesomeIcons} from '../../../utils/icons';
import {StyledMarginWidthDividerMd} from '../../@extended/Divider';

const selectList = [
  {
    title: 'Select Community',
    alternativeTitle: 'Add A Rental Community',
    id: 'community_sfid'
  },
  {
    title: 'Suggested Campaigns',
    alternativeTitle: 'Suggested Campaigns',
    id: 'campaign_type'
  },
  {title: 'Select Goal', alternativeTitle: 'Select Goal', id: 'goals'},
  {
    title: 'Enter Baseline Metrics',
    alternativeTitle: 'Enter Baseline Metrics',
    id: 'metrics'
  }
];

const SidebarMenu = ({currentPage}) => {
  const theme = useTheme();

  return (
    <Stack direction={'column'} sx={{pr: 3, width: 250}} divider={<StyledMarginWidthDividerMd />}>
      {selectList.map((item, idx) => {
        let isChecked = idx < currentPage;

        const icon = isChecked ? fontAwesomeIcons.faCircleCheck : fontAwesomeIcons.faCircle;
        let checkedLine;

        if (currentPage === idx) {
          checkedLine = (
            <Box
              width={3}
              height="100%"
              sx={{
                backgroundColor: theme.palette.secondary.main,
                left: -24,
                position: 'absolute'
              }}
            />
          );
        }

        return (
          <Stack key={item.id} direction="row" columnGap={1} alignItems="center" sx={{position: 'relative'}}>
            {checkedLine}
            <Stack direction="column" rowGap={1} sx={{padding: '16px 0'}}>
              <Stack direction="row" columnGap={1} alignItems="center">
                <FontAwesomeIcon icon={icon} style={isChecked ? {color: theme.palette.secondary.main} : {}} />
                <Typography variant="body1">{item.title}</Typography>
              </Stack>
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};

SidebarMenu.propTypes = {
  currentPage: PropTypes.number
};

export default SidebarMenu;
