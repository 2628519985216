import {Box, Paper, styled} from '@mui/material';

export const StyledPaperScrollY = styled(Paper)({
  overflow: 'auto',
  paddingRight: '16px',
  '&::-webkit-scrollbar': {
    height: '50%',
    width: '9px'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#c4c4c4',
    outline: 'none',
    borderRadius: 10
  }
});

export const StyledBoxScrollY = styled(Box)({
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    height: '50%',
    width: '9px'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#c4c4c4',
    outline: 'none',
    borderRadius: 10
  }
});
