import React, {useMemo} from 'react';
import {useFormContext} from 'react-hook-form';

import {Divider, Fade, Stack, Typography} from '@mui/material';

import {StyledBoxScrollY} from '../../@extended/StyledPaperScrollY';
import {FormAmountPercent, SimpleFormSelectWithLabel} from '../../@extended/Forms';

export const concessionOptions = [0, 0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.5, 3, 4, 5, 6, 7];

const formatToMonths = (value) => {
  if (value === 0) {
    return 'None';
  } else if (value === 0.25) {
    return '1 week free';
  } else if (value === 1) {
    return '1 month free';
  } else if (value % 1 === 0) {
    return `${value} months free`;
  } else {
    return `${value * 4} weeks free`;
  }
};

export const formatConcessionToFloat = (value) => {
  let newValue = 0;
  let splitted = parseFloat(value.split(' ')[0]);

  if (value.includes('week')) {
    newValue = splitted / 4;
  } else if (value.includes('month')) {
    newValue = splitted;
  }

  return newValue;
};

const EditMetrics = () => {
  const {watch} = useFormContext();
  const metrics = watch('metrics');

  const concessions = concessionOptions.map((ele) => {
    const label = formatToMonths(ele);
    return {id: label, name: label};
  });

  const mainComponent = useMemo(() => {
    return (
      <Fade timeout={1000} in={true}>
        <Stack direction="column" rowGap={3}>
          <Typography variant="h4" fontWeight={'fontWeightRegular'} textAlign="center">
            Establish baseline metrics
          </Typography>
          <Typography variant="h5" fontWeight={'fontWeightRegular'} color={'text.subtitle'} textAlign="center">
            These are the assumptions we will use to recommend the best offer for you.{<br />}Please edit them now to help us make a more
            accurate recommendation.
          </Typography>
          <Stack direction="column" rowGap={3} divider={<Divider />}>
            <Stack direction="column" rowGap={1}>
              <Typography variant="h5" fontWeight={'fontWeightMedium'}>
                Leasing
              </Typography>
              <Stack direction="row" columnGap={1}>
                <FormAmountPercent name="metrics.baseline_occupancy__c" label="Current Occupancy" />
                <FormAmountPercent name="metrics.baseline_bad_debt_rate__c" label="Bad Debt Rate" />
              </Stack>
              <Stack direction="row" columnGap={1}>
                <SimpleFormSelectWithLabel
                  name="metrics.previous_concessions__c"
                  options={[{id: 'default', name: 'Select a concession'}, ...concessions]}
                />
                <FormAmountPercent name="metrics.target_new_lease_trade_out_rate__c" label="Target increase in new lease trade out rate" />
              </Stack>
            </Stack>
            <Stack direction="column" rowGap={1}>
              <Typography variant="h5" fontWeight={'fontWeightMedium'}>
                Renewals
              </Typography>
              <Stack direction="row" columnGap={1}>
                <FormAmountPercent name="metrics.baseline_renewal_trade_out_rate__c" label="Current Average Renewal Conversions" />
                <FormAmountPercent name="metrics.target_renewal_trade_out_rate__c" label="Current renewal trade out rate" />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Fade>
    );
  }, [metrics]);

  return (
    <StyledBoxScrollY
      id="choose-metrics"
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      p={2}
      sx={{overflowY: 'scroll'}}
    >
      {mainComponent}
    </StyledBoxScrollY>
  );
};

export default EditMetrics;
