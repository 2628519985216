import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import {ButtonBase, Divider, Fade, Grid, Stack, styled, Typography, useTheme} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {getCommunities} from '../../../../app/store/reducers/entities';
import {fontAwesomeIcons} from '../../../utils/icons';
import {StyledBoxScrollY} from '../../@extended/StyledPaperScrollY';
import {useFormContext} from 'react-hook-form';
import {FormAmount, FormInput, GoogleAddressInputField} from '../../@extended/Forms';
import {getIsStakeAdmin, getTeamMemberRole} from '../../../../app/store/reducers/session';

export const ChooseButtonBase = styled(ButtonBase)(({theme}) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: '12px',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.06)',
  '&:hover': {
    borderColor: theme.palette.secondary.main
  },

  width: '160px',
  height: '175px'
}));

const ChooseCommunity = ({setCurrentPage, currentPage}) => {
  const theme = useTheme();
  const communities = useSelector(getCommunities);
  const isStakeAdmin = useSelector(getIsStakeAdmin);
  const teamMemberRole = useSelector(getTeamMemberRole);
  const [newCommunity, setNewCommunity] = useState(false);

  const isAllowedToAddCommunity = isStakeAdmin || teamMemberRole == 'Administrator';

  const {setValue} = useFormContext();

  const handleClick = (community) => {
    setValue('community_sfid', community.sfid);
    setValue('community_id', community.id);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (currentPage === 0) {
      setNewCommunity(false);
    }
  }, [currentPage]);

  const communitiesList = useMemo(() => {
    return [...communities].map((community) => {
      return (
        <Grid item key={`launch-type-icon-${community.sfid}`}>
          <ChooseButtonBase onClick={() => handleClick(community)}>
            <Stack direction="column" rowGap={1} alignItems="center" p={2}>
              <FontAwesomeIcon icon={fontAwesomeIcons['faHouseChimney']} size="3x" style={{color: theme.palette.grey[600]}} />
              <Typography variant="h6" fontWeight={'fontWeightRegular'} textAlign="center">
                {community.name}
              </Typography>
            </Stack>
          </ChooseButtonBase>
        </Grid>
      );
    });
  }, [communities]);

  const handleNewCommunity = () => {
    setNewCommunity(true);
    setCurrentPage(0.5);
  };

  const communitiesComponent = useMemo(() => {
    if (!newCommunity) {
      return (
        <Fade timeout={1000} in={true}>
          <Stack direction="column" rowGap={2}>
            <Typography variant="h4" fontWeight={'fontWeightRegular'} textAlign="center">
              Select Rental Community
            </Typography>
            <Grid container spacing={{xs: 2}} justifyContent="center">
              {communitiesList}
              {isAllowedToAddCommunity && (
                <Grid item>
                  <ChooseButtonBase onClick={handleNewCommunity}>
                    <Stack direction="column" rowGap={1} alignItems="center" p={2}>
                      <FontAwesomeIcon icon={fontAwesomeIcons.faHouseChimney} size="3x" style={{color: theme.palette.grey[600]}} />
                      <Typography variant="h6" fontWeight={'fontWeightRegular'} textAlign="center">
                        Add a new community
                      </Typography>
                    </Stack>
                  </ChooseButtonBase>
                </Grid>
              )}
            </Grid>
          </Stack>
        </Fade>
      );
    } else {
      return (
        <Fade timeout={1000} in={true}>
          <Stack direction="column" rowGap={2} width={'100%'}>
            <Typography variant="h4" fontWeight={'fontWeightRegular'} textAlign="center">
              Add a Rental Community
            </Typography>
            <Stack direction="column" rowGap={3} divider={<Divider />}>
              <Stack direction="column" rowGap={1}>
                <Typography variant="h5" fontWeight={'fontWeightMedium'}>
                  Rental Community Info
                </Typography>
                <Stack direction="row" columnGap={1}>
                  <FormInput name="community.name" label="Rental Community Name" />
                  <GoogleAddressInputField name="community.address" label="Address" />
                </Stack>
                <Stack direction="row" columnGap={1}>
                  <FormAmount name="community.units" label="Total Units" type="number" />
                  <FormAmount name="community.rent" label="Average Rent Today" />
                </Stack>
              </Stack>
              <Stack direction="column" rowGap={1}>
                <Typography variant="h5" fontWeight={'fontWeightMedium'}>
                  Listings
                </Typography>
                <FormInput name="community.link" label="Link to your online listings" />
              </Stack>
            </Stack>
          </Stack>
        </Fade>
      );
    }
  }, [newCommunity, communitiesList, communities]);

  return (
    <StyledBoxScrollY
      id="choose-community"
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      p={2}
      sx={{overflowY: 'scroll'}}
    >
      {communitiesComponent}
    </StyledBoxScrollY>
  );
};

export default ChooseCommunity;
