import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Stack} from '@mui/material';

import AccountDashboard from './AccountDashboard';
import CommunityDashboard from './CommunityDashboard';
import CampaignDashboard from './CampaignDashboard';
import SettingsHeader from './SettingsHeader/SettingsHeader';

import {settingsIds} from '../Profile/utils';
import {
  getDashboardCampaigns,
  getDashboardCommunities,
  setCampaignsSelected,
  setCommunitiesSelected,
  setDashboard
} from '../../../app/store/reducers/dashboard';
import {activeComponent, activeItem, endComponent, getOpenComponent, getOpenItem} from '../../../app/store/reducers/menu';
import {getCampaigns, getCommunities} from '../../../app/store/reducers/entities';
import {getAccountsPermissions, getIsStakeAdmin} from '../../../app/store/reducers/session';

const MenuDashboard = () => {
  const dispatch = useDispatch();

  const communities = useSelector(getCommunities);
  const campaigns = useSelector(getCampaigns);
  const isStakeAdmin = useSelector(getIsStakeAdmin);
  const accountsPermissions = useSelector(getAccountsPermissions);

  const openItem = useSelector(getOpenItem);
  const openComponent = useSelector(getOpenComponent);
  const dashboardCommunities = useSelector(getDashboardCommunities);
  const dashboardCampaigns = useSelector(getDashboardCampaigns);

  const isSettingsOpen = settingsIds.includes(openComponent);

  useEffect(() => {
    if (openItem == 'portfolio' && openComponent == 'home') {
      const comAmount = dashboardCommunities?.length || 0;
      dispatch(setDashboard({type: 'communities', data: {sfid: 'all', name: `All communities (${comAmount})`}}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardCommunities, openItem, openComponent]);

  useEffect(() => {
    if (openItem !== 'campaigns' && openComponent == 'home') {
      const campAmount = dashboardCampaigns?.length || 0;
      dispatch(setDashboard({type: 'campaigns', data: {sfid: 'all', name: `All campaigns (${campAmount})`}}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardCampaigns, openItem, openComponent]);

  const createHandleMenuClick = (menuItem, type) => {
    dispatch(endComponent('campaigns'));
    dispatch(activeComponent('home'));
    dispatch(setDashboard({type, data: menuItem}));
    let lowerCaseType = type.toLowerCase();
    let changedItem = lowerCaseType;

    if (menuItem.sfid === 'all' && lowerCaseType == 'communities') {
      changedItem = 'portfolio';
    } else if (menuItem.sfid === 'all' && lowerCaseType == 'campaigns') {
      changedItem = 'communities';
    }
    dispatch(activeItem(changedItem));

    if (lowerCaseType === 'communities' && menuItem.sfid != 'all') {
      const selectedCampiagns = campaigns.filter((campaign) => campaign.property_sfid === menuItem.sfid);
      dispatch(setCampaignsSelected(selectedCampiagns));
    } else if (lowerCaseType === 'communities' && menuItem.sfid === 'all') {
      dispatch(setCampaignsSelected(campaigns));
    }

    if (lowerCaseType === 'portfolio') {
      dispatch(setCommunitiesSelected(communities));
      dispatch(setCampaignsSelected(campaigns));
    }

    if (lowerCaseType === 'campaigns' && menuItem.sfid != 'all') {
      const selectedCommunity = communities.find((com) => com.sfid === menuItem.property_sfid);
      const selectedCampaigns = campaigns.filter((camp) => camp.property_sfid === selectedCommunity.sfid);
      dispatch(setDashboard({type: 'communities', data: {sfid: selectedCommunity.sfid, name: selectedCommunity.name}}));
      dispatch(setCampaignsSelected(selectedCampaigns));
    }
  };

  const additionalComponent = useMemo(() => {
    if (isSettingsOpen && openComponent) {
      return <SettingsHeader />;
    } else {
      return (
        <>
          <CommunityDashboard createHandleMenuClick={createHandleMenuClick} />
          <CampaignDashboard createHandleMenuClick={createHandleMenuClick} />
        </>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openComponent, isSettingsOpen, communities, campaigns]);

  return (
    <Stack direction="row" columnGap={2} sx={{width: '100%'}}>
      {(communities.length > 1 || isStakeAdmin || accountsPermissions.length > 0) && (
        <AccountDashboard createHandleMenuClick={createHandleMenuClick} />
      )}
      {additionalComponent}
    </Stack>
  );
};

export default MenuDashboard;
